import { default as indexcSIsHd2GqfMeta } from "/opt/buildhome/repo/pages/apps/[id]/index.vue?macro=true";
import { default as subscribeYXTDEGK9IXMeta } from "/opt/buildhome/repo/pages/apps/[id]/subscribe.vue?macro=true";
import { default as indexv5IxOYNPQtMeta } from "/opt/buildhome/repo/pages/apps/index.vue?macro=true";
import { default as change_45passwordOGNIsyewibMeta } from "/opt/buildhome/repo/pages/auth/change-password.vue?macro=true";
import { default as confirm_45emailLiQeATBVA4Meta } from "/opt/buildhome/repo/pages/auth/confirm-email.vue?macro=true";
import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as invitegmrrQd0504Meta } from "/opt/buildhome/repo/pages/auth/invite.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as passkeymr22GVRDvjMeta } from "/opt/buildhome/repo/pages/auth/passkey.vue?macro=true";
import { default as signup5HdPU4H68nMeta } from "/opt/buildhome/repo/pages/auth/signup.vue?macro=true";
import { default as successXuBflIzDlOMeta } from "/opt/buildhome/repo/pages/auth/success.vue?macro=true";
import { default as historycaD5jkpSSoMeta } from "/opt/buildhome/repo/pages/billing/history.vue?macro=true";
import { default as index16revmZ0K5Meta } from "/opt/buildhome/repo/pages/billing/index.vue?macro=true";
import { default as calendaryPechgWogzMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93BSJDmX4tzpMeta } from "/opt/buildhome/repo/pages/legal/[...slug].vue?macro=true";
import { default as menutzdAhuZSr6Meta } from "/opt/buildhome/repo/pages/menu.vue?macro=true";
import { default as messages5qoRx6Qq9MMeta } from "/opt/buildhome/repo/pages/messages.vue?macro=true";
import { default as notifications3rfhjEqjJjMeta } from "/opt/buildhome/repo/pages/notifications.vue?macro=true";
import { default as profileg2OzvUzQjKMeta } from "/opt/buildhome/repo/pages/profile.vue?macro=true";
import { default as indexvzxMIzL85PMeta } from "/opt/buildhome/repo/pages/properties/[id]/index.vue?macro=true";
import { default as preview6yUjIOoPYNMeta } from "/opt/buildhome/repo/pages/properties/[id]/preview.vue?macro=true";
import { default as indexglHWmb0IuZMeta } from "/opt/buildhome/repo/pages/properties/index.vue?macro=true";
import { default as new1y9RHo27idMeta } from "/opt/buildhome/repo/pages/properties/new.vue?macro=true";
import { default as reservationsmwo4bvRursMeta } from "/opt/buildhome/repo/pages/reservations.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as indexh0dcZPYGZFMeta } from "/opt/buildhome/repo/pages/stores/[storeId]/pages/[pageId]/index.vue?macro=true";
import { default as index8vvshD7gcNMeta } from "/opt/buildhome/repo/pages/stores/[storeId]/pages/index.vue?macro=true";
import { default as newnjfLl8IDbaMeta } from "/opt/buildhome/repo/pages/stores/[storeId]/pages/new.vue?macro=true";
import { default as newdhs3Tu2DDdMeta } from "/opt/buildhome/repo/pages/website/new.vue?macro=true";
export default [
  {
    name: "apps-id",
    path: "/apps/:id()",
    component: () => import("/opt/buildhome/repo/pages/apps/[id]/index.vue")
  },
  {
    name: "apps-id-subscribe",
    path: "/apps/:id()/subscribe",
    component: () => import("/opt/buildhome/repo/pages/apps/[id]/subscribe.vue")
  },
  {
    name: "apps",
    path: "/apps",
    component: () => import("/opt/buildhome/repo/pages/apps/index.vue")
  },
  {
    name: "auth-change-password",
    path: "/auth/change-password",
    meta: change_45passwordOGNIsyewibMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/change-password.vue")
  },
  {
    name: "auth-confirm-email",
    path: "/auth/confirm-email",
    meta: confirm_45emailLiQeATBVA4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/confirm-email.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordUaF9oDbyV0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-invite",
    path: "/auth/invite",
    meta: invitegmrrQd0504Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/invite.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-passkey",
    path: "/auth/passkey",
    meta: passkeymr22GVRDvjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/passkey.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signup5HdPU4H68nMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/signup.vue")
  },
  {
    name: "auth-success",
    path: "/auth/success",
    meta: successXuBflIzDlOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/success.vue")
  },
  {
    name: "billing-history",
    path: "/billing/history",
    component: () => import("/opt/buildhome/repo/pages/billing/history.vue")
  },
  {
    name: "billing",
    path: "/billing",
    component: () => import("/opt/buildhome/repo/pages/billing/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "legal-slug",
    path: "/legal/:slug(.*)*",
    meta: _91_46_46_46slug_93BSJDmX4tzpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/legal/[...slug].vue")
  },
  {
    name: "menu",
    path: "/menu",
    component: () => import("/opt/buildhome/repo/pages/menu.vue")
  },
  {
    name: "messages",
    path: "/messages",
    component: () => import("/opt/buildhome/repo/pages/messages.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/opt/buildhome/repo/pages/notifications.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/buildhome/repo/pages/profile.vue")
  },
  {
    name: "properties-id",
    path: "/properties/:id()",
    component: () => import("/opt/buildhome/repo/pages/properties/[id]/index.vue")
  },
  {
    name: "properties-id-preview",
    path: "/properties/:id()/preview",
    component: () => import("/opt/buildhome/repo/pages/properties/[id]/preview.vue")
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("/opt/buildhome/repo/pages/properties/index.vue")
  },
  {
    name: "properties-new",
    path: "/properties/new",
    component: () => import("/opt/buildhome/repo/pages/properties/new.vue")
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/opt/buildhome/repo/pages/reservations.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue")
  },
  {
    name: "stores-storeId-pages-pageId",
    path: "/stores/:storeId()/pages/:pageId()",
    component: () => import("/opt/buildhome/repo/pages/stores/[storeId]/pages/[pageId]/index.vue")
  },
  {
    name: "stores-storeId-pages",
    path: "/stores/:storeId()/pages",
    component: () => import("/opt/buildhome/repo/pages/stores/[storeId]/pages/index.vue")
  },
  {
    name: "stores-storeId-pages-new",
    path: "/stores/:storeId()/pages/new",
    component: () => import("/opt/buildhome/repo/pages/stores/[storeId]/pages/new.vue")
  },
  {
    name: "website-new",
    path: "/website/new",
    component: () => import("/opt/buildhome/repo/pages/website/new.vue")
  }
]