export default defineI18nLocale(async (locale) => {
    return {
        'Your temporary store name is currently {name}.': 'Your temporary store name is currently {name}.',
        'Host': 'Manager',
        'Owner': 'Catalog',
        'Staff': 'Sales',
        'HOST': 'Manager',
        'OWNER': 'Catalog',
        'STAFF': 'Sales',
        'Your store is currently {status}.': 'Your store is currently {status}.',
        'Your email {email} has been successfully verified. You can now go back to the App.': 'Your email {email} has been successfully verified. You can now go back to the App.',
        // onboard
        "We just need some basic info to get your store setup. You’ll be able to edit this later.": "We just need some basic info to get your store setup. You’ll be able to edit this later.",
        // Team
        "Team Members": "Team Members",
        "Invite a member": "Invite a member",
        "Add others to your account": "Add others to your account",
        "Add people who help manage your store. They will be able to see everything you can see.": "Add people who help manage your store. They will be able to see everything you can see.",
        "Send invite": "Send invite",
        // team
        "Invited at": "Invited at",
        "Resend invite": "Resend invite",
        "Cancel invite": "Cancel invite",
        "Remove user": "Remove user",
        // currency
        "Preferred currency": "Preferred currency",
        "Currency": "Currency",
        "Select a currency": "Select a currency",
        // language
        "Preferred language": "Preferred language",
        "Select a language": "Select a language",
        "Language": "Language",
        // passkey
        "Use biometrics": "Use biometrics",
        "Log in with your Face ID": "Log in with your Face ID",
        "Log in with your fingerprint": "Log in with your fingerprint",
        // auth/passkey
        "Next time, log in with face or fingerprint": "Next time, log in with face or fingerprint",
        "Using a passkey to log in is as easy as unclocking your device.": "Using a passkey to log in is as easy as unclocking your device.",
        "Yes, use fingerprint": "Yes, use fingerprint",
        "Skip for now": "Skip for now",
        "Biometric authentication is now set up": "Biometric authentication is now set up",
        "Turn in off anytime form settings.": "Turn in off anytime form settings.",
        // invite
        "Invite": "Invite",
        "The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite.": "The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite.",
        "You have been invited to join {team_name} by {invited_by}": "You have been invited to join {team_name} by {invited_by}",
        "Thank you for registering {name}": "Thank you for registering {name}",
        "We\'re glad that you\'re with us": "We\'re glad that you\'re with us",
        "Accept invitation": "Accept invitation",
        "Your account email address is": "Your account email address is",
        // passwords
        "Change password": "Change password",
        "Last updated": "Last updated",
        "Change your account password": "Change your account password",
        "Current password": "Current password",
        "New password": "New password",
        "Confirm password": "Confirm password",
        "Update password": "Update password",
        // change-password
        "Set a new password": "Set a new password",
        "Reset password": "Reset password",
        "Your password was reset successfully": "Your password was reset successfully",
        "Now you can go and login with your new password.": "Now you can go and login with your new password.",
        "Password reset links expire after an hour for security purposes, please request another password reset link below to continue": "Password reset links expire after an hour for security purposes, please request another password reset link below to continue",
        // forgot-password
        "Reset your password": "Reset your password",
        "Enter your e-mail and we will send you a link to change your password": "Enter your e-mail and we will send you a link to change your password",
        "Send password reset link": "Send password reset link",
        "Reset email sent": "Reset email sent",
        "Please check your inbox and make your that it\'s you or not and reset your password": "Please check your inbox and make your that it\'s you or not and reset your password",
        "Resent email": "Resent email",
        "Did you receive this email? Check your inbox or": "Did you receive this email? Check your inbox or",
        "Already have an account?": "Already have an account?",
        // email
        "Forgot password?": "Forgot password?",
        "Email address": "Email address",
        "Confirm email": "Confirm email",
        "Confirm your email address": "Confirm your email address",
        "We sent a confirmation email to": "We sent a confirmation email to",
        "Check your email and click on the confirmation link to continue.": "Check your email and click on the confirmation link to continue.",
        "Resend email": "Resend email",
        // confirm-email
        "Email is verified!": "Email is verified!",
        "This link has expired": "This link has expired",
        "Sorry! We couldn\'t verify your email, please request another link below to continue.": "Sorry! We couldn\'t verify your email, please request another link below to continue.",
        // phone
        "Phone number": "Phone number",
        "Add phone number": "Add phone number",
        "Confirm phone": "Confirm phone",
        "Enter verification code": "Enter verification code",
        "Add your phone number": "Add your phone number",
        "Please enter the six-digit code we just sent to your number": "Please enter the six-digit code we just sent to your number",
        "Please enter the five-digit code we just sent to your number": "Please enter the five-digit code we just sent to your number",
        "Resend code": "Resend code",
        "Code": "Code",
        "Use a different phone number": "Use a different phone number",
        "Wrong phone number?": "Wrong phone number?",
        // subscription-item
        "per month": "per month",
        "Cancel subscription": "Cancel subscription",
        // subscriptions
        "Billing and Subscriptions": "Billing and Subscriptions",
        "Get more information about managing your apps subscriptions, biling history, and more.": "Get more information about managing your apps subscriptions, biling history, and more.",
        // address
        "Address": "Address",
        "Country": "Country",
        "Street address": "Street address",
        "Street number": "Street number",
        "Apt, suite. (Optional)": "Apt, suite. (Optional)",
        "City": "City",
        "State": "State",
        "Postalcode": "Postalcode",
        // name
        "These details could be publicly available.": "These details could be publicly available.",
        // privacy
        "Delete account": "Delete account",
        "Permanently delete your account and all of your data.": "Permanently delete your account and all of your data.",
        "Are you sure you want to delete your account?": "Are you sure you want to delete your account?",
        "When you delete your account, all of your data will be deleted and can\'t be retrieved. Deleting your account cannot be undone.": "When you delete your account, all of your data will be deleted and can\'t be retrieved. Deleting your account cannot be undone.",
        "No, keep my account": "No, keep my account",
        "Yes, delete my account": "Yes, delete my account",
        // store/name
        "Name your store": "Name your store",
        "Your store name is currently {name}.": "Your store name is currently {name}.",
        "Name store": "Name store",
        "Store Name": "Store Name",
        // store/status
        "Your store is currently": "Your store is currently",
        // domain
        "Change to a new domain": "Change to a new domain",
        "Your domain name is currently": "Your domain name is currently",
        "Change domain": "Change domain",
        "Store details": "Store details",
        "Domain Name": "Domain Name",
        "Our suggested use is": "Our suggested use is",
        // auth/login
        "Sign in to your account": "Sign in to your account",
        "Welcome back you\'ve been missed": "Welcome back you\'ve been missed",
        "Continue with Email": "Continue with Email",
        "Continue with Phone Number": "Continue with Phone Number",
        "Continue with Bio": "Continue with Bio",
        "Continue with Google": "Continue with Google",
        "Continue with Facebook": "Continue with Facebook",
        "Don\'t have an account yet?": "Don\'t have an account yet?",
        // auth/signup
        "Hello, register now!": "Hello, register now!",
        "Let\'s create your account here": "Let\'s create your account here",
        // apps
        "We play nice with your favorite software.": "We play nice with your favorite software.",
        "Back to App Directory": "Back to App Directory",
        "Get Started": "Get Started",
        // apps/subscribe
        "Select a plan": "Select a plan",
        "Plan": "Plan",
        "billed yearly at": "billed yearly at",
        "Subscribe": "Subscribe",
        // success
        "Login Successful": "Login Successful",
        "You have successfully signed into your account. You can close this window and continue using the app.": "You have successfully signed into your account. You can close this window and continue using the app.",
        // Properties
        "Properties": "Properties",
        "Property": "Property",
        "properties": "properties",
        "property": "property",
        "DRAFT": "Draft",
        "PENDING": "Pending",
        "PUBLISHED": "Published",
        "ARCHIVED": "Archived",
        "APARTMENT": "Apartment",
        "HOUSE": "House",
        "LOFT": "Loft",
        "BOAT": "Boat",
        "CAMPER_RV": "Camper_rv",
        "CONDOMINIUM": "Condominium",
        "CHALET": "Chalet",
        "BED_BREAKFAST": "Bed breakfast",
        "VILLA": "Villa",
        "TENT": "Tent",
        "CABIN": "Cabin",
        "TOWNHOUSE": "Townhouse",
        "BUNGALOW": "Bungalow",
        "HUT": "Hut",
        "Sort by": "Sort by",
        "Property Information": "Property Information",
        "Property status": "Property status",
        "Property type": "Property type",
        "Property name": "Property name",
        "Property address": "Property address",
        "Property photos": "Property photos",
        "Your property": "Your property",
        "Country of location": "Country of location",
        "Photo captions": "Photo captions",
        "Manual property registration": "Manual property registration",
        "Choose a name for your property": "Choose a name for your property",
        "Property created successfully": "Property created successfully",
        "New property": "New property",
        "Manage properties": "Manage properties",
        "Import via Link/URL": "Import via Link/URL",
        "Maximum number of files to be uploaded is {0}": "Maximum number of files to be uploaded is {'{0}'}",
        "{0}: Invalid file size, file size should be smaller than {1}": "{'{0}'}: Invalid file size, file size should be smaller than {'{1}'}",
        "{0}: Invalid file type": "{'{0}'}: Invalid file type",
        "Insert the best photos of the property": "Insert the best photos of the property",
        "Drag and drop files to here to upload": "Drag and drop files to here to upload",
        "Minimum of 1 file": "Minimum of 1 file",
        "Enter the maximum number of guests your property can accommodate": "Enter the maximum number of guests your property can accommodate",
        "Enter a brief description of your property. Remember to include any features that will catch your attention": "Enter a brief description of your property. Remember to include any features that will catch your attention",
        "Daily value of the property": "Daily value of the property",
        "Don't worry, you can later change this value and create promotional values": "Don't worry, you can later change this value and create promotional values",
        "An error occurred while listing properties": "An error occurred while listing properties",
        "An error occurred while loading the property": "An error occurred while loading the property",
        "An error occurred while creating the property": "An error occurred while creating the property",
        // Currency
        "Australian dollar": "Australian dollar",
        "Brazilian real": "Brazilian real",
        "Bulgarian lev": "Bulgarian lev",
        "Canadian dollar": "Canadian dollar",
        "Chilean peso": "Chilean peso",
        "Chinese yuan": "Chinese yuan",
        "Colombian peso": "Colombian peso",
        "Costa Rican colon": "Costa Rican colon",
        "Croatian kuna": "Croatian kuna",
        "Czech koruna": "Czech koruna",
        "Danish krone": "Danish krone",
        "Emirati dirham": "Emirati dirham",
        "Euro": "Euro",
        "Hong Kong dollar": "Hong Kong dollar",
        "Hungarian forint": "Hungarian forint",
        "Indian rupee": "Indian rupee",
        "Israeli new shekel": "Israeli new shekel",
        "Japanese yen": "Japanese yen",
        "Malaysian ringgit": "Malaysian ringgit",
        "Mexican peso": "Mexican peso",
        "Moroccan dirham": "Moroccan dirham",
        "New Taiwan dollar": "New Taiwan dollar",
        "New Zealand dollar": "New Zealand dollar",
        "Norwegian krone": "Norwegian krone",
        "Peruvian sol": "Peruvian sol",
        "Philippine peso": "Philippine peso",
        "Polish zloty": "Polish zloty",
        "Pound sterling": "Pound sterling",
        "Romanian leu": "Romanian leu",
        "Saudi Arabian riyal": "Saudi Arabian riyal",
        "Singapore dollar": "Singapore dollar",
        "South African rand": "South African rand",
        "South Korean won": "South Korean won",
        "Swedish krona": "Swedish krona",
        "Swiss franc": "Swiss franc",
        "Thai baht": "Thai baht",
        "Turkish lira": "Turkish lira",
        "United States dollar": "United States dollar",
        "Uruguayan pes": "Uruguayan pes",
        // Generic
        "Dashboard": "Dashboard",
        "Daily": "Daily",
        "Booking": "Booking",
        "Calendar": "Calendar",
        "Messages": "Messages",
        "Account": "Account",
        "Preferences": "Preferences",
        "Personal info": "Personal info",
        "Information": "Information",
        "Details": "Details",
        "Support": "Support",
        "About": "About",
        "Pricing": "Pricing",
        "Profile": "Profile",
        "Billing": "Billing",
        "Settings": "Settings",
        "Platform": "Platform",
        "App Marketplace": "App Marketplace",
        "Sign up": "Sign up",
        "Sign in": "Sign in",
        "Today": "Today",
        "Privacy": "Privacy",
        "Security": "Security",
        "Store": "Store",
        "Free": "Free",
        "Menu": "Menu",
        "Home": "Home",
        "Bookings": "Bookings",
        "Status": "Status",
        "Logout": "Logout",
        "Listings": "Listings",
        "Need help?": "Need help?",
        "Visit our Help Center.": "Visit our Help Center.",
        "Terms of Service": "Terms of Service",
        "Privacy Policy": "Privacy Policy",
        "Cookie Policy": "Cookie Policy",
        "Version": "Version",
        "You": "You",
        "Save": "Save",
        "Edit": "Edit",
        "Cancel": "Cancel",
        "Change": "Change",
        "Continue": "Continue",
        "Configure": "Configure",
        "Verify": "Verify",
        "Apply": "Apply",
        "Login": "Login",
        "Enable": "Enable",
        "Disable": "Disable",
        "Accept": "Accept",
        "Decline": "Decline",
        "Search": "Search",
        "Filter": "Filter",
        "Filters": "Filters",
        "Opps": "Opps",
        "Name": "Name",
        "Full Name": "Full Name",
        "Password": "Password",
        "E-mail": "E-mail",
        "Phone": "Phone",
        "Time zone": "Time zone",
        "Clear filters": "Clear filters",
        "Refine your search": "Refine your search",
        "Back": "Back",
        "Next": "Next",
        "Welcome": "Welcome",
        "Welcome to Ermith!": "Welcome to Ermith!",
        "Not defined": "Not defined",
        "Go to the App": "Go to the App",
        "OTHER": "Other",
        "Select your country": "Select your country",
        "or": "or",
        "Use my location": "Use my location",
        "I don't know my zip code": "I don't know my zip code",
        "CEP": "CEP",
        "Neighborhood": "Neighborhood",
        "Complement": "Complement",
        "Number": "Number",
        "Postal Code": "Postal Code",
        "File": "File",
        "Caption": "Caption",
        "To share": "To share",
        "Conclude": "Conclude",
        "Saved": "Saved",
        // Forms
        "Invalid form, check previous steps": "Formulario no válido, revisa pasos anteriores",
        "Minimum 5 characters": "Minimum 5 characters",
        "Maximum 10000 characters": "Maximum 10000 characters",
        "Value must be positive": "Value must be positive",
        "Required": "Required",
        "Required field": "Required field",
        "Name is a required field": "Name is a required field",
        "Country is a required field": "Country is a required field",
        "CEP is a required field": "CEP is a required field",
        "State is a required field": "State is a required field",
        "City is a required field": "City is a required field",
        "Neighborhood is a required field": "Neighborhood is a required field",
        "Address is a required field": "Address is a required field",
        "Complement is a required field": "Complement is a required field",
        "Number is a required field": "Number is a required field",
        "60 character limit": "60 character limit",
    }
})
